<template>
  <div class="container">
    <div class="h-100 row">
      <div class="mx-auto my-auto col-12 col-md-10 bg-white rounded-lg">
        <div class="row">
          <div class="col-md-5 p-0">
            <div class="image-side-blur"></div>
            <div class="bg-text">
              <h1 class="font-weight-light text-white">Welcome to Cabrera Business CRM</h1>
              <p class="text-white mb-0">Please use your credentials to login.</p>
            </div>
          </div>
          <div class="col-md-7 py-10 px-11">
            <img src="../assets/image/logo.jpg" width="300" heigth="150">
            <div class="h5 font-weight-normal mt-5 mb-5">
              <span>Login</span>
            </div>
            <div class="mb-2">
              <input type="checkbox" id="checkbox" v-model="securitySocial">
              <label for="checkbox" class="ml-2">Sing-in with Social Security number</label>
            </div>
            <el-form :model="form" ref="form" :hide-required-asterisk="true">
              <el-form-item
                v-if="securitySocial"
                :label="$t('shareholder.socialsecurity')"
                prop="securitySocial"
                :rules="[
                  { required: true, message: 'Social Security is required' },
                ]"
              >
                <el-input
                  type="text"
                  v-model="form.securitySocial"
                  placeholder="e.g 554-55-998"
                  v-mask="'NNN-NN-NNNN'"
                ></el-input>
              </el-form-item>
              <el-form-item
              v-else
                :label="$t('login.email')"
                prop="email"
                :rules="[{ required: true, message: 'The field is required'},{ type: 'email', message: 'The format email invalid'}]"
              >
                <el-input type="text" v-model="form.email" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('login.password')"
                prop="password"
                :rules="[{ required: true, message: 'The field is required'}]"
              >
                <el-input
                  :type="passwordType"
                  v-model="form.password"
                  autocomplete="off"
                  @keyup.enter.native="submitForm('form')"
                ></el-input>
                <span class="position-absolute show-pwd" @click="showPwd">
                  <i class="fal" :class="passwordType === 'password' ? 'fa-eye-slash' : 'fa-eye'"></i>
                </span>
              </el-form-item>
              <div class="d-flex justify-content-between align-items-center">
                <router-link to="recovery">Forgot password?</router-link>
                <el-form-item class="mb-0">
                  <el-button
                    class="btn-big"
                    type="primary"
                    :loading="loading"
                    @click="submitForm('form')"
                  >LOGIN</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/services/api/auth";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
        type: 'email',
        securitySocial: "",
      },
      securitySocial:false,
      loading: false,
      passwordType: "password"
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch("logout");
          if(this.securitySocial) {
            this.form.type = 'securitySocial'
          }
          auth
            .login(this.form)
            .then(response => {
              this.$store.dispatch("token", {
                token: response.token
              });
              localStorage.setItem('access', response.token);
              auth.me(response.token)
                .then(response => {
                    this.$store.dispatch("preference", response.user);
                    /*const respuesta={
                      user:{
                        id: response.user.id,
                        name: response.user.name,
                        discriminator: response.user.discriminator
                      }
                    }
                    localStorage.setItem('responseUser', JSON.stringify(respuesta));*/
                    if(response.user.discriminator === 'shareholder') {
                      this.$router.push("/admin/document-manager");
                    } else {
                      this.$router.push("/admin");
                    }
                    
                })
                .catch(() =>{
                  this.$store.dispatch("logout");
                  this.$message({
                    message: this.$store.getters.message.message,
                    type: "error",
                    customClass: "message-error"
                  });
                })
            })
            .catch(() => {
              this.$store.dispatch("logout");
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = null;
      } else {
        this.passwordType = "password";
      }
    }
  }
};
</script>
<style lang="scss">
.show-pwd {
  top: 4px;
  right: 15px;
}

.image-side-blur{
  background: url(../assets/image/bg.jpg) no-repeat top;
  background-size: cover;
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  padding: 70px 40px;
  height: 100%;
  width: 100%;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.bg-text {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
</style>
